import CustomButton from '@components/Button';
import CheckBox from '@components/Checkbox';
import Dropdown from '@components/Dropdown';
import Modal from '@components/Modal';
import CustomTextfield from '@components/Textfield';
import ImageUploadComp from '@components/UploadImage';
import { CircularProgress, Grid2, Typography } from '@mui/material';
import { getModifiers } from '@services/categories';
import { addMenu, updateMenu, uploadImage } from '@services/menu';
import { getValidations } from '@utils/index';
import React from 'react';
import { ChangeEvent, useEffect, useReducer, useRef, useState } from 'react';
import Validator from 'simple-react-validator';
import { CategoryType, ResponseDataType } from 'types/services';

export interface MenuData {
  id?: string;
  name: string;
  menu_category_id: string;
  description: string;
  price: number | null;
  modifier_group_ids: string[];
  image?: string;
}

export default function AddEditMenuModal({
  open,
  closeModal,
  menu,
  categories,
  onComplete
}: {
  open: boolean;
  closeModal: () => void;
  menu?: any;
  categories: CategoryType[];
  onComplete: () => void;
}) {
  const validator = useRef(new Validator(getValidations()));
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [modifiers, setModifiers] = useState<any>([]);
  const [image, setImage] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<MenuData>({
    name: '',
    description: '',
    menu_category_id: '',
    price: null,
    modifier_group_ids: []
  });

  useEffect(() => {
    const restaurantId = localStorage.getItem('restaurantId');
    if (restaurantId) {
      setLoading(true);
      getModifiers(restaurantId)
        .then((res) => {
          const resData: ResponseDataType<any> = res.data;
          setModifiers(resData.items);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (menu) {
      setData(menu);
    }
  }, [menu]);

  const onClickSave = async () => {
    if (validator.current.allValid()) {
      setLoading(true);
      const restaurantId = localStorage.getItem('restaurantId');
      let imagePath = '';
      if (image) {
        const imageRes = await uploadImage(image, 'menu');
        if (!imageRes.error) {
          imagePath = imageRes.name || '';
        }
      }
      const dataToSend = {
        id: menu.id || null,
        name: data.name,
        description: data.description,
        image: imagePath,
        restaurant_id: restaurantId,
        price: Number(data.price),
        modifier_group_ids: data.modifier_group_ids,
        menu_category_id: data.menu_category_id
      };
      if (menu.id) {
        updateMenu(dataToSend)
          .then((res) => {
            const resData: ResponseDataType<{ id: string }> = res.data;
            if (resData.success) {
              onComplete();
              closeModal();
            }
          })
          .finally(() => {
            setLoading(true);
          });
      } else {
        addMenu(dataToSend)
          .then((res) => {
            const resData: ResponseDataType<{ id: string }> = res.data;
            if (resData.success) {
              onComplete();
              closeModal();
            }
          })
          .finally(() => {
            setLoading(true);
          });
      }
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [name]: value });
  };

  const handleClickCheckbox = (id: string, value: boolean) => {
    let arr = data.modifier_group_ids;
    if (value) {
      if (!arr.includes(id)) {
        arr.push(id);
      }
    } else {
      arr = arr.filter((a) => a !== id);
    }
    setData({ ...data, modifier_group_ids: arr });
  };

  return (
    <Modal
      open={open}
      title={menu ? 'Update menu' : 'Add Menu'}
      maxWidth="md"
      fullScreen={false}
      content={
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12 }}>
            <ImageUploadComp handleSelectedFile={(file) => setImage(file)} image={data.image} />
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <CustomTextfield
              fullWidth
              name="name"
              label="Name"
              value={data.name}
              onChange={handleChange}
              validator={validator}
              isRequired
            />
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <CustomTextfield
              fullWidth
              name="description"
              label="Description"
              value={data.description}
              onChange={handleChange}
              validator={validator}
              isRequired
            />
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <CustomTextfield
              fullWidth
              type="number"
              name="price"
              label="Price($)"
              value={data?.price || ''}
              onChange={handleChange}
              validator={validator}
              isRequired
            />
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Dropdown
              label="Category"
              options={categories.map((c) => ({ id: c.id, label: c.name }))}
              value={data.menu_category_id}
              inputName="menu_category_id"
              onChange={handleChange}
              validator={validator}
              required
            />
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Typography sx={{ mt: 2 }} variant={'subtitle1'}>
              Choice Groups
            </Typography>
            <Typography color={'gray'} variant={'subtitle2'} mb={2}>
              Add choices for this item that customers can choose. You can create a bundle or combo
              set, add toppings and sides or item customisations
            </Typography>
            {modifiers.map((m: any) => (
              <React.Fragment key={m.id}>
                <CheckBox
                  label={m.name}
                  onClick={(status) => handleClickCheckbox(m.id, status)}
                  checked={data.modifier_group_ids.includes(m.id)}
                />
                <br />
              </React.Fragment>
            ))}
          </Grid2>
        </Grid2>
      }
      closeModal={closeModal}
      actions={
        <>
          <CustomButton variant="outlined" onClick={closeModal}>
            Cancel
          </CustomButton>
          <CustomButton variant="contained" onClick={onClickSave} disabled={loading}>
            {loading && <CircularProgress sx={{ mr: '10px' }} size="20" />}
            Save
          </CustomButton>
        </>
      }
    />
  );
}
