import { MenuData } from '@pages/ManageMenu/AddEditMenu';
import axiosInstance from '@services/axios';

export const getMenus = (restaurantId: string) => {
  return axiosInstance.get(`/menu/${restaurantId}`);
};

export const addMenu = (data: MenuData) => {
  return axiosInstance.post('/menu', data);
};

export const updateMenu = (data: MenuData) => {
  return axiosInstance.patch('/menu', data);
};

export const uploadImage = async (file: File, folder: string) => {
  try {
    const res = await axiosInstance.post(`/file/signed-url`, {
      folder,
      type: file.type,
      name: file.name
    });

    if (res.data?.success) {
      const { url, name } = res.data;
      const blobData = new Blob([await file.arrayBuffer()], { type: file.type });
      await fetch(url, { method: 'put', body: blobData });

      return { error: false, name: `${folder}/${name}` };
    } else {
      throw new Error('Failed to get signed url');
    }
  } catch (err: any) {
    return { error: true, message: err?.message || 'Something went wrong' };
  }
};
