import Modal from '@components/Modal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid2, IconButton, Switch, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Item, MenuItem } from 'types/services';

import OutOfStockModal from './OutOfStockModal';

export default function MenuItemDetails({
  open,
  closeModal,
  item,
  fullScreen,
  selectedMenuName
}: {
  open: boolean;
  closeModal: () => void;
  item: MenuItem;
  fullScreen?: boolean;
  selectedMenuName: string;
}) {
  const [selectedItem, setSelectedItem] = useState<MenuItem>();
  const [isAdditions, setIsAdditions] = useState<boolean>(false);
  const [showOutOfStockModal, setShowOutOfStockModal] = useState<boolean>(false);

  useEffect(() => {
    if (item) {
      setSelectedItem(item);
    }
  }, [item]);

  let items: Item[] = [];
  const len = selectedItem?.modifier_groups?.length || 0;
  for (let i = 0; i < len; i++) {
    const mItems = selectedItem?.modifier_groups[i]?.items || [];
    items = [...items, ...mItems];
  }

  return (
    <>
      <Modal
        hideCloseButton={true}
        title={
          <Grid2 container justifyContent={'center'} alignItems={'center'} spacing={2} size={12}>
            <Grid2 container alignItems={'center'} size={12}>
              <Grid2 size={3} display="flex" justifyContent="flex-start">
                <IconButton
                  aria-label="close"
                  onClick={() => closeModal()}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    flex: 'none'
                  }}>
                  <ArrowBackIcon />
                </IconButton>
              </Grid2>

              <Grid2
                size={6}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ paddingInline: '10px' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '10px'
                  }}>
                  <div>
                    <Typography
                      component="div"
                      sx={{
                        fontWeight: 'bold',
                        color: '#bebebe',
                        padding: '0px'
                      }}>
                      {selectedMenuName}
                    </Typography>
                    <Typography
                      component="div"
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '18px',
                        padding: '0px'
                      }}>
                      {selectedItem?.name}
                      <span
                        style={{
                          color: '#bebebe',
                          paddingLeft: '5px'
                        }}>
                        ${selectedItem?.price}
                      </span>
                    </Typography>
                  </div>
                </div>
                <Typography component="div" sx={{ width: '100px', textAlign: 'center' }}>
                  <Typography
                    component="div"
                    sx={{
                      fontWeight: 'bold',
                      padding: '0px'
                    }}>
                    {selectedItem?.is_available ? 'In Stock' : 'Out of Stock'}
                  </Typography>
                  <Switch
                    onChange={({ target: { checked } }) => {
                      if (selectedItem?.id) {
                        setSelectedItem({
                          ...selectedItem,
                          is_available: checked
                        });
                      }

                      if (!checked) {
                        setShowOutOfStockModal(true);
                      }
                    }}
                    checked={selectedItem?.is_available}
                  />
                </Typography>
              </Grid2>
            </Grid2>
          </Grid2>
        }
        open={open}
        closeModal={closeModal}
        content={
          <>
            <Grid2
              container
              justifyContent="center"
              bgcolor={'#edf2f3'}
              sx={{
                height: '100%',
                paddingTop: '20px'
              }}>
              <Grid2 size={6}>
                <Typography
                  component="div"
                  sx={{
                    mr: 1,
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                    marginBottom: '3px',
                    padding: '12px',
                    background: '#fff',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}>
                  <Typography component="div" fontSize={'18px'}>
                    <strong>Side Additions</strong>{' '}
                  </Typography>
                  <Switch onChange={() => setIsAdditions(!isAdditions)} defaultChecked={false} />
                </Typography>
                {items?.map((item, index: number) => (
                  <Typography
                    key={item?.name}
                    component="div"
                    sx={{
                      mr: 1,
                      marginBottom: '3px',
                      padding: '12px',
                      background: '#fff',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      cursor: 'pointer',
                      borderBottomLeftRadius: index === items?.length - 1 ? '10px' : '0px',
                      borderBottomRightRadius: index === items?.length - 1 ? '10px' : '0px'
                    }}>
                    <Typography component="div">
                      <strong>{item?.name}</strong>{' '}
                      <span
                        style={{
                          color: '#bebebe'
                        }}>
                        ${item?.price}
                      </span>
                    </Typography>
                    <Switch
                      sx={{
                        cursor: !isAdditions ? 'not-allowed' : 'pointer'
                      }}
                      disabled={!isAdditions}
                      checked={item?.is_available === 1 ? true : false}
                    />
                  </Typography>
                ))}
              </Grid2>
            </Grid2>
          </>
        }
        fullScreen={fullScreen}
      />
      {/* Out of Stock Modal */}
      {selectedItem && (
        <OutOfStockModal
          selectedItem={selectedItem}
          isOpen={showOutOfStockModal}
          setIsOpen={setShowOutOfStockModal}
        />
      )}
    </>
  );
}
