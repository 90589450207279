import configuration from '@config/index';
import TokenService from '@services/tokenService';
import { useCommonStore } from '@store/index';
import axios from 'axios';

const instance = axios.create({
  baseURL: configuration.apiBaseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (res) => {
    console.log(res);
    return res;
  },
  async (err: any) => {
    console.log('err', err);

    useCommonStore.getState().setSnackBarData({
      open: true,
      type: 'error',
      message: err.response?.data?.error || err.message
    });
    if (err.response?.status === 403 || err.response?.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    }
    return Promise.reject(err);
  }
);

export default instance;
