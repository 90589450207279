import CustomButton from '@components/Button';
import CustomTextfield from '@components/Textfield';
import { CircularProgress, Typography, useTheme } from '@mui/material';
import { login } from '@services/auth';
import { getRestaurantById } from '@services/restaurant';
import { useRestaurantStore } from '@store/index';
import { getValidations } from '@utils/index';
import { ChangeEvent, useReducer, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Validator from 'simple-react-validator';
import { LoginDataType } from 'types/services';

export default function Login() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [data, setData] = useState<LoginDataType>({
    email: '',
    password: ''
  });

  const addRestaurantData = useRestaurantStore((state) => state.addRestaurant);

  const validator = useRef(new Validator(getValidations()));

  const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setData((state) => ({ ...state, [name]: value }));
  };

  const onClickLogin = () => {
    if (validator.current.allValid()) {
      setLoading(true);
      login(data)
        .then(async (res) => {
          const apiRes = res.data;
          if (apiRes.success) {
            const restaurantId = apiRes.user.restaurant_id;
            localStorage.setItem('token', apiRes.user.token);
            localStorage.setItem('user', JSON.stringify(apiRes.user));
            localStorage.setItem('restaurantId', restaurantId);
            const restaurantRes = await getRestaurantById(restaurantId);
            if (restaurantRes.data.success) {
              addRestaurantData(restaurantRes.data.restaurant);
            }
            navigate('/review-menu-confirmation');
          }
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false);
        });
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  return (
    <>
      <CustomTextfield
        fullWidth
        color="secondary"
        label="Enter Email Address or Phone Number"
        value={data.email}
        name="email"
        onChange={handleChange}
        isRequired
        validator={validator}
        variant="filled"
      />
      <CustomTextfield
        type="password"
        value={data.password}
        name="password"
        fullWidth
        color="secondary"
        label="Password"
        onChange={handleChange}
        isRequired
        validator={validator}
        variant="filled"
      />
      <CustomButton
        sx={{ mt: 2 }}
        fullWidth
        variant="contained"
        color="secondary"
        onClick={onClickLogin}
        disabled={loading}>
        {loading && <CircularProgress size={20} sx={{ mr: '10px' }} />}
        Sign In
      </CustomButton>
      <Typography
        component="div"
        color="secondary"
        sx={{
          textAlign: 'center',
          mt: 2,
          cursor: 'pointer',
          width: '100%',
          a: {
            color: theme.palette.secondary.dark
          }
        }}>
        <Link to="/register">Register here</Link>
      </Typography>
    </>
  );
}
