import CustomButton from '@components/Button';
import Header from '@components/Header';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PrintIcon from '@mui/icons-material/Print';
import { Box, Grid2, IconButton, Typography } from '@mui/material';
import { useCommonStore } from '@store/index';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ActiveOrderDetails from './ActiveOrderDetails';
import InProgressOrderDetails from './InProgressOrderDetails';
import IssueWithOrderModal from './IssueWithOrderModal';
import KitchenStatusesModal from './KitchenStatusesModal';
import MakeOutOfStockModal from './makeOutOfStock/MakeOutOfStockModal';
import NoOrder from './NoOrder';
import OrderCard from './OrderCard';
import PreTimeModal from './PrepTimeModal';
import ReadyOrderDetails from './ReadyOrderDetails';

export type KitchenStatus = {
  title: string;
  description: string;
  color: string;
};

export type OrderItemType = {
  name: string;
  quantity: number;
  seletedMenuName: string;
  price: number;
};

export type OrderType = {
  id: string;
  orderNumber: string;
  orderStatus: string;
  prepTime?: string;
  orderTime: string;
  deliveryDate: string;
  customerName: string;
  restaurantName: string;
  orderNotes?: string;
  items: OrderItemType[];
};

export type IssueWithOrderActionType = {
  title: string;
  action: () => void;
};

export default function ActiveOrders() {
  const navigate = useNavigate();

  const [orderingStatus, setOrderingStatus] = useState<string>('Accepting');

  const [showKitchenStatusModal, setshowKitchenStatusModal] = useState<boolean>(false);

  const [showPrepTimeModal, setshowPrepTimeModal] = useState<boolean>(false);
  const [prepTime, setPrepTime] = useState<number>(15);

  const [showIssueWithOrderModal, setshowIssueWithOrderModal] = useState<boolean>(false);
  const setSelectedNavItem = useCommonStore((state) => state.setSelectedNavItem);

  const [orders, setOrders] = useState<OrderType[]>([
    {
      id: '1',
      orderNumber: '#ac123',
      orderStatus: 'NEW',
      orderTime: '2022-10-12T12:00:00.000Z',
      deliveryDate: '2022-10-12',
      customerName: 'John Doe',
      restaurantName: 'Shakir',
      orderNotes: "I'm having a problem with my order",
      items: [
        {
          name: 'Chicken Burger',
          quantity: 1,
          price: 10,
          seletedMenuName: 'Fast Food'
        },
        {
          name: 'Chicken Burger',
          quantity: 1,
          price: 15,
          seletedMenuName: 'Fast Food'
        },
        {
          name: 'Chiken Burger',
          quantity: 2,
          price: 16,
          seletedMenuName: 'Fast Food'
        },
        {
          name: 'Pizza',
          quantity: 3,
          price: 17,
          seletedMenuName: 'Fast Food'
        },

        {
          name: 'Salad',
          quantity: 1,
          price: 18,
          seletedMenuName: 'Fast Food'
        }
      ]
    },
    {
      id: '2',
      orderNumber: '#ac124',
      orderStatus: 'NEW',
      orderTime: '2022-10-12T12:00:00.000Z',
      deliveryDate: '2022-10-12',
      customerName: 'John Doe',
      restaurantName: 'Shakir',
      orderNotes: "I'm having a problem with my order",
      items: [
        {
          name: 'Chicken Burger',
          quantity: 1,
          price: 10,
          seletedMenuName: 'Fast Food'
        },
        {
          name: 'Chicken Burger',
          quantity: 1,
          price: 10,
          seletedMenuName: 'Fast Food'
        },
        {
          name: 'Chicken Burger',
          quantity: 1,
          price: 10,
          seletedMenuName: 'Fast Food'
        }
      ]
    },
    {
      id: '3',
      orderNumber: '#ac125',
      orderStatus: 'NEW',
      orderTime: '2022-10-12T12:00:00.000Z',
      deliveryDate: '2022-10-12',
      customerName: 'John Doe',
      restaurantName: 'Shakir',
      orderNotes: "I'm having a problem with my order",
      items: [
        {
          name: 'Chicken Burger',
          quantity: 1,
          price: 10,
          seletedMenuName: 'Fast Food'
        },
        {
          name: 'Chicken Burger',
          quantity: 1,
          price: 10,
          seletedMenuName: 'Fast Food'
        },
        {
          name: 'Chicken Burger',
          quantity: 2,
          price: 10,
          seletedMenuName: 'Fast Food'
        },
        {
          name: 'Chicken Burger',
          quantity: 3,
          price: 10,
          seletedMenuName: 'Fast Food'
        }
      ]
    },
    {
      id: '4',
      orderNumber: '#ac126',
      orderStatus: 'NEW',
      orderTime: '2022-10-12T12:00:00.000Z',
      deliveryDate: '2022-10-12',
      customerName: 'John Doe',
      restaurantName: 'Shakir',
      orderNotes: "I'm having a problem with my order",
      items: [
        {
          name: 'Chicken Burger',
          quantity: 1,
          price: 10,
          seletedMenuName: 'Fast Food'
        },
        {
          name: 'Chicken Burger',
          quantity: 1,
          price: 10,
          seletedMenuName: 'Fast Food'
        },
        {
          name: 'Chicken Burger',
          quantity: 2,
          price: 10,
          seletedMenuName: 'Fast Food'
        }
      ]
    }
  ]);
  const [selectedOrder, setSelectedOrder] = useState<OrderType | null>(null);

  const [kitchenStatuses, setKitchenStatuses] = useState<KitchenStatus[]>([
    {
      title: 'Accepting',
      description: 'Business as usual',
      color: 'green'
    },
    {
      title: 'Busy',
      description: 'Need more prep time',
      color: 'orange'
    },

    {
      title: 'Paused',
      description: 'Pause now orders',
      color: 'red'
    }
  ]);

  const [showMakeSomethingOutOfStockModal, setshowMakeSomethingOutOfStockModal] =
    useState<boolean>(false);

  const [issueWithOrderActions, setIssueWithOrderActions] = useState<IssueWithOrderActionType[]>([
    {
      title: 'Adjust Prep Time',
      action: () => setshowPrepTimeModal(true)
    },
    {
      title: 'Make Something as out of Stock',
      action: () => setshowMakeSomethingOutOfStockModal(true)
    },
    {
      title: 'Support Live Chat',
      action: () => navigate('/help-support')
    },
    {
      title: 'Cancel Order',
      action: () => alert('Cancel Order')
    }
  ]);

  const ConfirmPrepTime = (defaultPrepTime?: number) => {
    const updateOrder: any = {
      ...selectedOrder,
      orderStatus: 'IN PROGRESS',
      prepTime: defaultPrepTime || prepTime
    };
    setSelectedOrder(updateOrder);
    setOrders(
      orders?.map((order) => {
        if (order.id === updateOrder.id) {
          return updateOrder;
        }
        return order;
      })
    );
    setshowPrepTimeModal(false);
  };

  const MarkReadyToPickup = () => {
    const updateOrder: any = {
      ...selectedOrder,
      orderStatus: 'READY TO PICKUP',
      prepTime: 0
    };
    setSelectedOrder(updateOrder);
    setOrders(
      orders?.map((order) => {
        if (order.id === updateOrder.id) {
          return updateOrder;
        }
        return order;
      })
    );
    setshowPrepTimeModal(false);
  };

  const [totalPrice, setTotalPrice] = useState<number>(0);
  useEffect(() => {
    const price: any = selectedOrder?.items?.reduce((acc, item) => {
      return acc + item.price;
    }, 0);
    setTotalPrice(price);
  }, [selectedOrder]);

  const onClickOrderHistoryBtn = () => {
    setSelectedNavItem({ title: 'Order History' });
    navigate('/order-history');
  };

  return (
    <>
      <Grid2 container spacing={0}>
        <Grid2 size={4}>
          <Header
            bgColor={
              (orderingStatus === 'Paused' && 'red') ||
              (orderingStatus === 'Busy' && 'orange') ||
              'transparent'
            }>
            <Typography
              component="div"
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Typography variant="h6" fontWeight={'bold'}>
                Orders
              </Typography>

              <CustomButton
                sx={{
                  py: '6px',
                  px: 1,
                  borderRadius: '4px',
                  border: '1px solid lightgrey',
                  mr: 2,
                  bgcolor: '#fff'
                }}
                onClick={() => {
                  setshowKitchenStatusModal(true);
                }}>
                <Typography
                  sx={{
                    borderLeft: `5px solid ${
                      (orderingStatus === 'Accepting' && 'green') ||
                      (orderingStatus === 'Busy' && 'orange') ||
                      (orderingStatus === 'Paused' && 'red') ||
                      '#fff'
                    }`,
                    paddingInline: 1.5,
                    fontSize: 12,
                    color: '#000'
                  }}>
                  {orderingStatus}
                </Typography>
              </CustomButton>
            </Typography>
          </Header>
          <Box width={'100%'} sx={{ borderBottom: '4px solid lightgrey' }}></Box>
          {orders.length === 0 && (
            <Grid2
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1
              }}
              size={12}
              container>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  mt: 8
                }}>
                Currently no new orders
              </Typography>
              <CustomButton
                variant="contained"
                sx={{
                  background: 'lightgrey',
                  borderRadius: '99999px',
                  color: '#000',
                  fontWeight: 'bold',
                  paddingInline: 5
                }}
                onClick={onClickOrderHistoryBtn}>
                See History
              </CustomButton>
            </Grid2>
          )}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              py: 3,
              gap: 2
            }}>
            {/* New Orders */}
            {orders.filter((order) => order.orderStatus === 'NEW').length > 0 && (
              <Box sx={{ width: '100%' }}>
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    textAlign: 'left',
                    ml: 2
                  }}>
                  NEED ACTIONS
                </Typography>

                {orders
                  ?.filter((order) => order.orderStatus === 'NEW')
                  .map((order, i) => (
                    <OrderCard
                      selectedOrder={selectedOrder}
                      order={order}
                      key={i}
                      onOrderClick={() => setSelectedOrder(order)}
                    />
                  ))}
              </Box>
            )}

            {/* New Orders */}
            {orders.filter(
              (order) =>
                order.orderStatus === 'IN PROGRESS' || order.orderStatus === 'READY TO PICKUP'
            ).length > 0 && (
              <Box sx={{ width: '100%' }}>
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    textAlign: 'left',
                    ml: 2
                  }}>
                  IN THE KITCHEN
                </Typography>

                {orders
                  ?.filter(
                    (order) =>
                      order.orderStatus === 'IN PROGRESS' || order.orderStatus === 'READY TO PICKUP'
                  )
                  .map((order, i) => (
                    <OrderCard
                      selectedOrder={selectedOrder}
                      order={order}
                      key={i}
                      onOrderClick={() => setSelectedOrder(order)}
                    />
                  ))}
              </Box>
            )}

            <CustomButton
              variant="contained"
              sx={{
                background: 'lightgrey',
                borderRadius: '99999px',
                color: '#000',
                fontWeight: 'bold',
                paddingInline: 5,
                mx: 'auto',
                mt: 3
              }}
              onClick={onClickOrderHistoryBtn}>
              See History
            </CustomButton>
          </Box>
        </Grid2>

        {/* No Orders */}
        {Boolean(!selectedOrder?.id) && (
          <Grid2 size={8} bgcolor={'#fff'}>
            <NoOrder />
          </Grid2>
        )}

        {/* Active Order Details */}
        {selectedOrder?.orderStatus === 'NEW' && (
          <ActiveOrderDetails
            selectedOrder={selectedOrder}
            setshowIssueWithOrderModal={setshowIssueWithOrderModal}
            setshowPrepTimeModal={setshowPrepTimeModal}
            ConfirmPrepTime={ConfirmPrepTime}
          />
        )}

        {/* In Progress Orders */}
        {selectedOrder?.orderStatus === 'IN PROGRESS' && (
          <InProgressOrderDetails
            selectedOrder={selectedOrder}
            setshowIssueWithOrderModal={setshowIssueWithOrderModal}
            setshowPrepTimeModal={setshowPrepTimeModal}
            MarkReadyToPickup={() => {
              MarkReadyToPickup();
            }}
          />
        )}

        {/* Ready to pickup order details */}
        {selectedOrder?.orderStatus === 'READY TO PICKUP' && (
          <ReadyOrderDetails
            selectedOrder={selectedOrder}
            setshowIssueWithOrderModal={setshowIssueWithOrderModal}
            setshowPrepTimeModal={setshowPrepTimeModal}
            //   MarkReadyToPickup={() => {
            //     MarkReadyToPickup();
            //   }}
          />
        )}
      </Grid2>
      {/* Kitchen status modal */}
      <KitchenStatusesModal
        showKitchenStatusModal={showKitchenStatusModal}
        setshowKitchenStatusModal={setshowKitchenStatusModal}
        kitchenStatuses={kitchenStatuses}
        setOrderingStatus={setOrderingStatus}
        orderingStatus={orderingStatus}
      />

      {/* Prep time modal */}
      <PreTimeModal
        showPrepTimeModal={showPrepTimeModal}
        setshowPrepTimeModal={setshowPrepTimeModal}
        prepTime={prepTime}
        setPrepTime={setPrepTime}
        onConfirm={() => {
          ConfirmPrepTime();
        }}
      />
      {/* Issue with order modal */}
      <IssueWithOrderModal
        showIssueWithOrderModal={showIssueWithOrderModal}
        setShowIssueWithOrderModal={setshowIssueWithOrderModal}
        issueWithOrderActions={issueWithOrderActions}
      />

      {/* Make Something out of Stock modal */}
      <MakeOutOfStockModal
        showMakeSomethingOutOfStockModal={showMakeSomethingOutOfStockModal}
        setShowMakeSomethingOutOfStockModal={setshowMakeSomethingOutOfStockModal}
      />
    </>
  );
}
