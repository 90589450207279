import CustomButton from '@components/Button';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PrintIcon from '@mui/icons-material/Print';
import { Box, Grid2, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { OrderType } from '.';

export default function InProgressOrderDetails(props: {
  selectedOrder: OrderType | null;
  setshowIssueWithOrderModal: any;
  setshowPrepTimeModal: any;
  MarkReadyToPickup: any;
}) {
  const { selectedOrder, setshowIssueWithOrderModal, MarkReadyToPickup } = props;
  const [totalPrice, setTotalPrice] = useState<number>(0);
  useEffect(() => {
    const price: any = selectedOrder?.items?.reduce((acc, item) => {
      return acc + item.price;
    }, 0);
    setTotalPrice(price);
  }, [selectedOrder]);
  return (
    <React.Fragment>
      <Grid2 size={8} bgcolor={'#fff'}>
        <Box
          sx={{
            display: 'flex',
            px: 2,
            py: '12px',
            height: '65px',
            justifyContent: 'space-between'
          }}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 'bold'
              }}>
              {selectedOrder?.restaurantName}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 'bold',
                color: '#bebebe'
              }}>
              {selectedOrder?.orderNumber} . {selectedOrder?.items?.length} items
            </Typography>
          </Box>
          <Box display={'flex'} gap={1}>
            <IconButton
              aria-label="delete"
              size="small"
              sx={{
                py: '6px',
                borderRadius: '4px',
                border: '2px solid lightgrey',
                bgcolor: '#fff',
                width: '40px'
              }}>
              <PrintIcon fontSize="inherit" />
            </IconButton>
            <CustomButton
              variant="outlined"
              sx={{
                py: '6px',
                borderRadius: '4px',
                border: '2px solid lightgrey',
                bgcolor: '#fff'
              }}
              onClick={() => {
                setshowIssueWithOrderModal(true);
              }}>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  color: '#000'
                }}>
                Issue with Order
              </Typography>
            </CustomButton>
          </Box>
        </Box>
        <Box width={'100%'} sx={{ borderBottom: '2px solid lightgrey' }}></Box>
        <Box>
          <Box
            sx={{
              px: 2
            }}
            width={'100%'}>
            <Typography
              component="div"
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'start'}
              alignItems={'start'}
              sx={{
                borderBottom: '1px solid lightgrey',
                width: '100%',
                py: 2
              }}>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 'bold',
                  textAlign: 'start'
                }}>
                Assigning a Driver
              </Typography>
              {/* <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    textAlign: 'center'
                  }}>
                  Keep in mind
                </Typography> */}
            </Typography>
            <Typography
              component="div"
              sx={{
                borderBottom: '1px solid lightgrey',
                width: '100%',
                py: 2
              }}>
              <CustomButton
                variant="contained"
                sx={{
                  background: 'lightgrey',
                  borderRadius: '99999px',
                  color: '#000',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingInline: 2,
                  fontSize: 12
                }}
                disabled>
                <PostAddIcon /> Text Order
              </CustomButton>
            </Typography>
            <Typography
              component="div"
              sx={{
                width: '100%'
              }}>
              {selectedOrder?.items?.map((item, i) => {
                return (
                  <Typography
                    key={i}
                    sx={{
                      fontSize: 12,
                      fontWeight: 'bold',
                      color: '#000',
                      width: '100%',
                      borderBottom: '1px solid lightgrey',
                      py: 1,
                      px: 1,
                      bgcolor: '#fff'
                    }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: 'grey',
                        width: '100%'
                      }}>
                      {item.seletedMenuName}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        mt: 2
                      }}>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 'bold',
                          color: '#000'
                        }}
                        key={i}>
                        {item.quantity} X{' '}
                        <span
                          style={{
                            color: 'grey'
                          }}>
                          --
                        </span>{' '}
                        {item.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 'normal',
                          color: '#000'
                        }}
                        key={i}>
                        ${item.price}
                      </Typography>
                    </Box>
                  </Typography>
                );
              })}
            </Typography>
          </Box>
        </Box>
        <Typography
          component="div"
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          <Grid2
            container
            width={'100%'}
            spacing={2}
            px={3}
            py={2}
            sx={{ borderTop: '1px solid lightgrey' }}>
            <Grid2
              size={12}
              bgcolor={'#fff'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              py={2}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '60%'
                }}>
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#000'
                  }}>
                  {selectedOrder?.items?.length} total items
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#000'
                  }}>
                  Subtotal:
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  color: '#000'
                }}>
                ${totalPrice}
              </Typography>
            </Grid2>

            <CustomButton
              sx={{ width: '100%' }}
              variant="contained"
              color="primary"
              onClick={MarkReadyToPickup}>
              Order ready for pickup
            </CustomButton>
          </Grid2>
        </Typography>
      </Grid2>
    </React.Fragment>
  );
}
