import CustomSnackbar from '@components/Snackbar';
import { Typography } from '@mui/material';
import Routes from '@routes/index';
import { useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';

function App() {
  // const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    OneSignal.init({
      appId: 'f17670e4-5c7d-40e5-8d46-bf4b21f7b2bb'
    });
  }, []);

  // useEffect(() => {
  //   if (!initialized) {
  //     const initializeOneSignal = async () => {
  //       await OneSignal.init({
  //         appId: 'f17670e4-5c7d-40e5-8d46-bf4b21f7b2bb',
  //         safari_web_id: 'web.onesignal.auto.0818a4e7-118f-4fc1-b0e2-07892e811a2a',
  //         notifyButton: {
  //           enable: true
  //         },
  //         allowLocalhostAsSecureOrigin: true
  //       });
  //       setInitialized(true);
  //       OneSignal.setConsentRequired(true);
  //       const isSupported = OneSignal.Notifications.isPushSupported();
  //       console.log('isSupported', isSupported);
  //     };
  //     initializeOneSignal();
  //   }
  // }, [initialized]);

  return (
    <Typography component="div" sx={{ height: '100vh', margin: 'auto' }}>
      <Routes />;
      <CustomSnackbar />
    </Typography>
  );
}

export default App;
