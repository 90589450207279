import CustomButton from '@components/Button';
import Modal from '@components/Modal';
import CallIcon from '@mui/icons-material/Call';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PrintIcon from '@mui/icons-material/Print';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import { Box, Grid2, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { OrderType } from '.';

export default function ReadyOrderDetails(props: {
  selectedOrder: OrderType | null;
  setshowIssueWithOrderModal: any;
  setshowPrepTimeModal: any;
  //   MarkReadyToPickup: any;
}) {
  const { selectedOrder, setshowIssueWithOrderModal } = props;
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [showContactUserModal, setShowContactUserModal] = useState(false);

  const onClickPhoneIcon = () => {
    const anchorTag = document.createElement('a');
    anchorTag.href = 'tel:+1234567890';
    anchorTag.click();
  };

  useEffect(() => {
    const price: any = selectedOrder?.items?.reduce((acc, item) => {
      return acc + item.price;
    }, 0);
    setTotalPrice(price);
  }, [selectedOrder]);
  return (
    <React.Fragment>
      <Grid2 size={8} bgcolor={'#fff'}>
        <Box
          sx={{
            display: 'flex',
            px: 2,
            py: '12px',
            height: '65px',
            justifyContent: 'space-between'
          }}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 'bold'
              }}>
              {selectedOrder?.restaurantName}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 'bold',
                color: '#bebebe'
              }}>
              {selectedOrder?.orderNumber} . {selectedOrder?.items?.length} items
            </Typography>
          </Box>
          <Box display={'flex'} gap={1}>
            <IconButton
              aria-label="delete"
              size="small"
              sx={{
                py: '6px',
                borderRadius: '4px',
                border: '2px solid lightgrey',
                bgcolor: '#fff',
                width: '40px'
              }}>
              <PrintIcon fontSize="inherit" />
            </IconButton>
            <CustomButton
              variant="outlined"
              sx={{
                py: '6px',
                borderRadius: '4px',
                border: '2px solid lightgrey',
                bgcolor: '#fff'
              }}
              onClick={() => {
                setshowIssueWithOrderModal(true);
              }}>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  color: '#000'
                }}>
                Issue with Order
              </Typography>
            </CustomButton>
          </Box>
        </Box>
        <Box width={'100%'} sx={{ borderBottom: '2px solid lightgrey' }}></Box>
        <Box
          sx={{
            px: 2
          }}
          width={'100%'}>
          <Typography
            component="div"
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'start'}
            alignItems={'start'}
            sx={{
              width: '100%',
              py: 2
            }}>
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: 'bold',
                textAlign: 'start'
              }}>
              Driver arrives in 5 minutes
            </Typography>
            <Box
              sx={{
                width: '100%',
                height: '200px',
                border: '1px solid lightgrey',
                bgcolor: '#EDF2F3',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                position: 'relative',
                borderRadius: '12px'
              }}>
              <Box
                sx={{
                  height: '100%',
                  zIndex: 2,
                  width: '40%',
                  bgcolor: '#fff',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  px: '10%',
                  gap: 3,
                  flex: 1
                }}>
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 3
                  }}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: 'grey'
                      }}>
                      Contact Driver
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: '#000'
                      }}>
                      Contact Driver
                    </Typography>
                  </Box>
                  <IconButton
                    sx={{
                      color: '#000',
                      bgcolor: '#fff',
                      boxShadow: '0px 3px 10px lightgrey'
                    }}
                    aria-label="add to shopping cart"
                    onClick={() => setShowContactUserModal(true)}>
                    <CallIcon />
                  </IconButton>
                </Typography>
                <Box>
                  <Typography
                    sx={{
                      fontSize: 12,
                      fontWeight: 'bold',
                      color: 'grey'
                    }}>
                    How is this Driver doing?
                  </Typography>
                  <CustomButton
                    sx={{ width: '100%', bgcolor: '#fff', color: '#000' }}
                    variant="contained">
                    Driver Feedback
                  </CustomButton>
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  color: 'grey',
                  flex: 3,
                  textAlign: 'center'
                }}>
                MAP Place Holder
              </Typography>
            </Box>
          </Typography>

          <Typography
            component="div"
            sx={{
              width: '100%'
            }}>
            {selectedOrder?.items?.map((item, i) => {
              return (
                <Typography
                  key={i}
                  sx={{
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#000',
                    width: '100%',
                    borderBottom: '1px solid lightgrey',
                    py: 1,
                    px: 1,
                    bgcolor: '#fff'
                  }}>
                  <Typography
                    sx={{
                      fontSize: 12,
                      fontWeight: 'bold',
                      color: 'grey',
                      width: '100%'
                    }}>
                    {item.seletedMenuName}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      mt: 2
                    }}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 'bold',
                        color: '#000'
                      }}
                      key={i}>
                      {item.quantity} X{' '}
                      <span
                        style={{
                          color: 'grey'
                        }}>
                        --
                      </span>{' '}
                      {item.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 'normal',
                        color: '#000'
                      }}
                      key={i}>
                      ${item.price}
                    </Typography>
                  </Box>
                </Typography>
              );
            })}
          </Typography>
        </Box>

        <Typography
          component="div"
          sx={{
            width: '80%',
            p: 3,
            my: 4,
            fontSize: 14,
            mx: 'auto',
            bgcolor: '#f0070728',
            fontWeight: 'bold',
            borderRadius: '6px',
            display: 'flex',
            alignItems: 'center'
          }}>
          <SpeakerNotesIcon
            sx={{
              color: 'red',
              fontSize: 20
            }}
          />
          <span style={{ marginLeft: '10px' }}>{selectedOrder?.orderNotes}</span>
        </Typography>

        <Typography
          component="div"
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          <Grid2
            container
            width={'100%'}
            spacing={2}
            px={3}
            py={2}
            sx={{ borderTop: '1px solid lightgrey' }}>
            <Grid2
              size={12}
              bgcolor={'#fff'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              py={2}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '60%'
                }}>
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#000'
                  }}>
                  {selectedOrder?.items?.length} total items
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#000'
                  }}>
                  Subtotal:
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  color: '#000'
                }}>
                ${totalPrice}
              </Typography>
            </Grid2>

            <CustomButton sx={{ width: '100%' }} variant="contained" color="primary">
              Pickup Order
            </CustomButton>
          </Grid2>
        </Typography>
      </Grid2>

      <Modal
        title={<></>}
        open={showContactUserModal}
        closeModal={() => {
          setShowContactUserModal(false);
        }}
        hideCloseButton={true}
        maxWidth="sm"
        fullScreen={false}
        content={
          <React.Fragment>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                gap: 4,
                p: 4
              }}>
              <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>Test 617-123-4567</Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                This number will only work if you call from 617-123-4567.If you are no longer using
                that phone number, please contact support
              </Typography>
              <Typography
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'end'
                }}>
                <CustomButton
                  sx={{
                    width: '100px',
                    bgcolor: 'red',
                    color: '#fff',
                    fontWeight: 'bold'
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onClickPhoneIcon();
                    setShowContactUserModal(false);
                  }}>
                  OK
                </CustomButton>
              </Typography>
            </Box>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
