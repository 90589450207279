import CustomButton from '@components/Button';
import { Box, Divider, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

export default function WhatOutOfStock(props: { setSteps: any }) {
  const { setSteps } = props;
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
      <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>What is Out of Stock?</Typography>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={1}
        name="radio-buttons-group"
        sx={{ width: '100%', mt: 5 }}>
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'start', alignItems: 'start', py: 3 }}>
          <Radio value={1} />
          <Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>Entire item</Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'grey' }}>
              The main item the customer (Tiramisu Gelato)
            </Typography>
          </Typography>
        </Box>

        <Divider sx={{ width: '100%' }} />
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'start', alignItems: 'start', py: 3 }}>
          <Radio value={2} />
          <Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>Amodifier</Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'grey' }}>
              The add-ons or toppings(eg. Andes Mints, Regular etc)
            </Typography>
          </Typography>
        </Box>
      </RadioGroup>
      <Box
        sx={{
          mt: 5,
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          gap: 2,
          width: '100%'
        }}>
        <CustomButton
          sx={{
            width: '150px',
            background: 'lightgrey',
            color: '#000'
          }}
          variant="contained">
          Back
        </CustomButton>
        <CustomButton
          sx={{ width: '150px' }}
          variant="contained"
          color="primary"
          onClick={() => setSteps(2)}>
          Next
        </CustomButton>
      </Box>
    </Box>
  );
}
