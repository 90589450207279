import CustomButton from '@components/Button';
import Modal from '@components/Modal';
import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid2,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MenuItem } from 'types/services';

export default function OutOfStockModal({
  isOpen,
  setIsOpen,
  selectedItem
}: {
  selectedItem: MenuItem;
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
}) {
  const navigate = useNavigate();

  return (
    <Modal
      title={<strong>{`How long should ${selectedItem?.name} be out of stock?`}</strong>}
      open={isOpen}
      closeModal={() => setIsOpen(false)}
      content={
        <>
          <Grid2
            container
            justifyContent="center"
            sx={{
              padding: '20px',
              width: '100%'
            }}>
            <FormControl sx={{ width: '100%' }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group">
                <Grid2
                  container
                  width={'100%'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  size={12}>
                  <FormControlLabel value="fourHours" control={<Radio />} label="Four Hours" />
                  <Typography
                    component="div"
                    sx={{
                      fontSize: '16px',
                      fontWeight: '600'
                    }}>
                    Until 1:00 PM
                  </Typography>
                </Grid2>
                <Divider />
                <Grid2
                  container
                  width={'100%'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  size={12}>
                  <FormControlLabel value="endOfDay" control={<Radio />} label="End of Day" />
                  <Typography
                    component="div"
                    sx={{
                      fontSize: '16px',
                      fontWeight: '600'
                    }}>
                    Until 11:59 PM
                  </Typography>
                </Grid2>
                <Divider />
                <Grid2
                  container
                  width={'100%'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  size={6}>
                  <FormControlLabel value="indefinitely" control={<Radio />} label="Indefinitely" />
                  <Typography
                    component="div"
                    sx={{
                      fontSize: '16px',
                      fontWeight: '600'
                    }}>
                    Until manually reactivated
                  </Typography>
                </Grid2>
              </RadioGroup>
            </FormControl>
          </Grid2>
        </>
      }
      actions={
        <>
          <Grid2 container width={'100%'} justifyContent={'end'} alignItems={'center'} size={12}>
            <Typography component="div">
              <CustomButton
                default
                variant="contained"
                onClick={() => setIsOpen(false)}
                sx={{ mr: 2 }}>
                Cancel
              </CustomButton>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsOpen(false);
                  navigate('/confirm-menu');
                }}>
                Apply
              </CustomButton>
            </Typography>
          </Grid2>
        </>
      }
      fullScreen={false}
      maxWidth="sm"
    />
  );
}
