import axiosInstance from '@services/axios';

export const updateRestaurant = (data: {
  id: string;
  auto_confirm_order?: boolean;
  new_order_alter_volume?: 'quiet' | 'loud';
  dasher_arriving_alert?: boolean;
}) => {
  return axiosInstance.patch(`/restaurant`, data);
};

export const getRestaurantById = (id: string) => {
  return axiosInstance.get(`/restaurant/${id}`);
};
