const config: any = {
  dev: {
    apiBaseUrl: 'https://api.gobig.app',
    storageUrl: 'https://storage.gobig.app'
  },
  uat: {
    apiBaseUrl: 'https://uat.url.com',
    storageUrl: 'https://storage.gobig.app'
  },
  prod: {
    apiBaseUrl: 'https://prod.url.com',
    storageUrl: 'https://storage.gobig.app'
  }
};

export default config[process.env.REACT_APP_ENV?.trim() || 'dev'];
